<template>
    <div class="entity-tags">
        <span @click="clickedTag(tag.slug)" v-for="(tag, index) in tags" :key="`entity-tag-${index}`" class="tag btn-info">
            {{ tag.title }}
        </span>
    </div>
</template>

<script>
export default {
    name: 'EntityTags',
    props: {
        tags: {
            type: Array,
            default: () => {
                return []
            }
        }
    },
    data() {
        return {}
    },
    methods: {
        clickedTag(slug) {
            this.$emit('clicked-tag', slug);
        }
    }
}
</script>

<style scoped lang="scss">
    @import "@/styles/common";

    .entity-tags {
        display: flex;
        margin: 0 -0.5rem;
        flex-wrap: wrap;

        .tag {
            margin: 0 0.5rem 1rem 0.5rem;
            display: block;
            padding: 5px 15px;
            cursor: default;
        }
    }
</style>